import './App.scss';

import { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { getBrandConfig } from './api/settingsApi';
import AppRoutes from './router';

function App() {
  useEffect(() => {
    async function loadBrandConfig() {
      const { data: brandConfig } = await getBrandConfig();
      window.localStorage.setItem('brandConfig', JSON.stringify(brandConfig));
      const { brandName = '', favicon = '' } = brandConfig;
      document.title = `${brandName} Admin`;
      const metaDesc = document.querySelector('meta[name="description"]');
      if (metaDesc) {
        metaDesc.setAttribute('content', `${brandName} admin platform`);
      }
      const faviconLink = document.querySelector('link[rel="icon"]');
      if (faviconLink) {
        faviconLink.setAttribute('href', favicon);
      }
    }
    loadBrandConfig();
  }, []);
  return (
    <div className="App">
      {/* 配置弹窗 */}
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Router>
        <AppRoutes />
      </Router>
    </div>
  );
}

export default App;
