/* eslint-disable no-dupe-keys */

import axios from 'axios';
import { toast } from 'react-toastify';

const apiUrl = process.env.NODE_ENV === 'production' ? `${location.origin}/api` : process.env.REACT_APP_API_URL;
// 创建一个 axios 实例
const axiosClient = axios.create({
  baseURL: apiUrl,
  timeout: 10000, // 设置超时时间
  withCredentials: process.env.NODE_ENV === 'production', // 跨域请求时是否需要使用凭证
});

// 请求拦截器
axiosClient.interceptors.request.use(
  (config) => {
    const newConfig = config;
    // 在请求发送前可以对请求进行一些处理，例如添加 token 等
    const token = localStorage.getItem('token');
    const lang = localStorage.getItem('i18nextLng') || 'en-US';
    if (token) {
      // config.headers["Authorization"] = `Bearer ${token}`;
      newConfig.headers.Authorization = token;
    }
    newConfig.headers['Accept-Language'] = lang;
    return newConfig;
  },
  (error) => {
    // 处理请求错误
    console.error(error);
    return Promise.reject(error);
  },
);

// 响应拦截器
axiosClient.interceptors.response.use(
  (response) => {
    if (response.status === 200 && response.config.responseType === 'blob') {
      return response.data;
    }
    // 在响应返回后可以对响应进行一些处理，例如判断错误码等
    if (response.status === 200 && +response.data.code === 200) {
      return response.data;
    }
    // if (response.status === 500) window.location.href = '/500';
    // 未登录或登录过期跳转到首页 等开发完之后放开这个注释
    if (
      (response.data && response.data.code === 403) ||
      (response.data && response.data.code === 401)
    ) {
      // window.history.pushState({}, '', '/login'); // navigate to the login route
      window.location.href = '/login';
      // remove token
      localStorage.removeItem('token');
    }
    // if (response.data && response.data.code === 500) {
    toast.error(response.data.msg);
    return Promise.reject(response.data.msg);
    // }
  },
  (error) => {
    // 网络错误回到这里
    // window.location.href = '/500';
    return Promise.reject(error);
  },
);

export default axiosClient;
