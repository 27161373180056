import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import contractAddressReducer from './contractAddressSlice';
import globalReducer from './globalSlice';
import homeReducer from './homeSlice';

const store = configureStore({
  reducer: {
    global: globalReducer,
    auth: authReducer,
    home: homeReducer,
    contractAddress: contractAddressReducer,
  },
});

export default store;
