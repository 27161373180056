/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    token: '',
    userName: '',
    account: '',
    orgId: null,
  },
  reducers: {
    setUserName: (state, action) => {
      state.userName = action.payload;
    },

    setAccount: (state, action) => {
      state.account = action.payload;
    },

    setToken: (state, action) => {
      state.token = action.payload;
    },

    setOrgId: (state, action) => {
      state.orgId = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setUserName, setAccount, setToken, setOrgId,
} = authSlice.actions;

export default authSlice.reducer;
