import axiosClient from './axiosClient';

// ---------------网络列表--------------------
// 获取网络列表信息
export const getNetworkList = async (params) => axiosClient.get('/sys/chain/list', { params }).catch(() => ({ data: { list: [], count: 0 } }));

// 增加网络
export const addNetworkItem = async (params) => axiosClient.post('/sys/chain/save', params);

// 编辑网络
export const editNetworkItem = async (params) => axiosClient.post('/sys/chain/update', params);

// ---------------币种--------------------
// 获取币种列表信息
export const getCoinList = async (params) => axiosClient.get('/sys/currency/list', { params }).catch(() => ({ data: [] }));

// 增加币种
export const addCoinItem = async (params) => axiosClient.post('/sys/currency/save', params);

// 修改币种
export const editCoinItem = async (params) => axiosClient.post('/sys/currency/update', params);

// 删除币种
export const deleteCoinItem = async (id) => axiosClient.post(`/sys/currency/delete/${id}`);

// 获取法币列表
export const getLegalTenderList = async (params) => axiosClient.get('/sys/currency/legalTender/list', { params }).catch(() => ({ data: [] }));

// GET /sys/currency/symbol/available 获取机构可用数字货币列表
export const getAvailableCoinList = async (params) => axiosClient.get('/sys/currency/symbol/available', { params }).catch(() => ({ data: [] }));

// --------------合约地址------------------
// 获取合约地址列表信息
export const getContractAddressList = async (params) => axiosClient.get('/sys/chain-wallet/list', { params }).catch(() => ({ data: { list: [], count: 0 } }));

// 编辑合约地址
export const editContractAddress = async (params) => axiosClient.post('/sys/chain-wallet/update', params);

// POST /sys/chain-wallet/save
// 存储公共合约信息
export const addContractAddress = async (params) => axiosClient.post('/sys/chain-wallet/save', params);


// --------------多签管理------------------
/** 查询多签日志 */
export const getMultiSigLogs = async (params) => axiosClient.get('/sys/sign/log/list', { params }).catch(() => ({ data: { list: [], count: 0 } }));
/** 查询多签提案列表 */
export const getMultiSigList = async (params) => axiosClient.get('/sys/sign/list', { params }).catch(() => ({ data: { list: [], count: 0 } }));

// POST /sys/sign/start
export const addMultiSigProposalBE = async (params) => axiosClient.post('/sys/sign/start', params);

// POST /sys/sign/approval
/** 添加签名 */
export const addSignature = async (params) => axiosClient.post('/sys/sign/approval', params);

/** 确认提案结果 */
export const confirmProposalRes = async (params) => axiosClient.post('/sys/sign/confirm', params);

export const getMultiSigDetail = async (proposalId) => axiosClient.get('/sys/sign/detail', { params: { proposalId } });

export const getTransferProposalList = async (params) => axiosClient.get('/sys/sign/list/transferProposal', { params }).catch(() => ({ data: { list: [], count: 0 } }));

export const denyTransferProposal = async (params) => axiosClient.post('/sys/sign/deny', params);

export const getBrandConfig = async () => axiosClient.get('/sys/config/brand');

export const updateBrandConfig = async (params) => axiosClient.post('/sys/config/brand/update', params);

export const storeDataInSecurityTool = async (baseURL, data) => axiosClient.post('/api/storeInSessionBody', data, { baseURL, withCredentials: false });
