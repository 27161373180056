/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const globalSlice = createSlice({
  name: 'global',
  initialState: {
    currentChainObj: JSON.parse(localStorage.getItem('lastChainObj') || '{"chainId": null}'),
    chainList: [],
    roleIndex: null,
    managerType: null,
    coinTypeList: [],
    orgList: [],
    selectedOrg: {},
  },
  reducers: {
    setCurrentChainObj: (state, action) => {
      state.currentChainObj = action.payload;
      localStorage.setItem('lastChainObj', JSON.stringify(action.payload));
    },
    setChainList: (state, action) => {
      state.chainList = action.payload;
      if (!state.currentChainObj.chainId
        || !state.chainList?.find((item) => item.chainId === state.currentChainObj.chainId)) {
        state.currentChainObj = state.chainList.find((item) => !!item.isDefault);
      }
    },
    setCoinTypeList: (state, action) => {
      state.coinTypeList = action.payload;
    },
    setRoleIndex: (state, action) => {
      state.roleIndex = action.payload;
    },
    setManagerType: (state, action) => {
      state.managerType = action.payload;
    },
    setOrgList: (state, action) => {
      state.orgList = action.payload;
      if (state.orgList.length > 0) {
        if (!state.selectedOrg.orgId) {
          state.selectedOrg = state.orgList[0];
        } else {
          state.selectedOrg = state.orgList.find((item) => item.orgId === state.selectedOrg.orgId);
        }
      }
    },
    selectOrg: (state, action) => {
      state.selectedOrg = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setChainList,
  setRoleIndex,
  setManagerType,
  setCoinTypeList,
  setCurrentChainObj,
  setOrgList,
  selectOrg,
} = globalSlice.actions;

export default globalSlice.reducer;
