import PropTypes from 'prop-types';

export default function Loading({ white = false }) {
  const brandConfig = window.localStorage.getItem('brandConfig');
  const { logo, logoDarkMode } = brandConfig ? JSON.parse(brandConfig) : {};
  return (
    <div className="page page-center">
      <div className="container container-slim py-4">
        <div className="text-center">
          <div className="mb-3">
            <a className="navbar-brand navbar-brand-autodark">
              {((white && logoDarkMode) || logo) && <img style={{ objectPosition: 'center', objectFit: 'scale-down' }} src={white ? logoDarkMode : logo} height={36} width={96} alt="" />}
            </a>
          </div>
          <div className="text-secondary mb-3">Loading...</div>
          <div className="progress progress-sm">
            <div className="progress-bar progress-bar-indeterminate" />
          </div>
        </div>
      </div>
    </div>
  );
}

Loading.propTypes = {
  white: PropTypes.bool,
};
