
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const homeSlice = createSlice({
  name: 'home',
  initialState: {
    chartDataObj: {},
  },
  reducers: {
    setChartDataObj: (state, action) => {
      state.chartDataObj = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setChartDataObj } = homeSlice.actions;

export default homeSlice.reducer;
